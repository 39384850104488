// router.js
import { createRouter, createWebHashHistory } from 'vue-router';
import HellowWorld from '../components/mainIndex.vue';
 
// 路由配置
const routes = [
  { path: '/', component: HellowWorld }
];
 
// 创建router实例
const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
 
export default router;