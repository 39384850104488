import { createApp } from 'vue'
import App from './App.vue'
import http from './utils/http'
import router from './router';
import ElementPlus from 'element-plus'
const app = createApp(App)
app.provide('http', http)
app.use(router)
app.use(ElementPlus)
app.config.globalProperties.$http = http
app.mount('#app')
