import  https  from "./http.js"
import  http  from "./https.js"
 
 
// 以get方式进行请求
 
export function axiosGet(url,parameter={}) {
 
    //debugger
    return https({
 
        url: url,
 
        method: 'get',
 
        params: parameter
 
    })
}
 
 
 
// 以post方式进行请求
 
export function axiosPost(url,parameter={}) {
 
    return https({
 
        url: url,
 
        method:'post' ,
 
        data: parameter
 
    })
 
}

export function UrlPost(url,parameter={}) {
 
    return http({
 
        url: url,
 
        method:'post' ,
 
        data: parameter
 
    })
 
}