import {  axiosGet,axiosPost,UrlPost } from './request'//
export const getInffer = (data) => {
    return axiosPost('/api/infervideo', data)
}

export const getImg = (data) => {
    return axiosGet('/api/last_infer_pic', data)
}

export const stopInffer = (data) => {
    return axiosPost('/api/stop_inffer', data)
}

export const getEvent = (data) => {
    return axiosPost('/api/getevents', data)
}


